fnResize();
window.onresize = function () {
    fnResize();
};

function fnResize() {
    var deviceWidth =
        document.documentElement.clientWidth || window.innerWidth;
    if (deviceWidth >= 1080) {
        deviceWidth = 1080;
    }
    // if (deviceWidth <= 320) {
    //     deviceWidth = 320;
    // }
    document.documentElement.style.fontSize = deviceWidth / 10.8 + "px";
}