
//注册方式过滤
exports.loginChannel = (value) => {
  let loginChannel = "";
  switch (value) {
    case "qq": {
      loginChannel = "QQ";
      break;
    }
    case "wx": {
      loginChannel = "微信";
      break;
    }
    case "phone": {
      loginChannel = "手机号";
      break;
    }
    case "guest": {
      loginChannel = "游客";
      break;
    }
  }
  return loginChannel;
};

//登录方式
exports.loginType = (val) => {
  let loginType = "";
  switch (val) {
    case 1: {
      loginType = "三方登录";
      break;
    }
    case 2: {
      loginType = "token登录";
      break;
    }
    case 3: {
      loginType = "设备码登录";
      break;
    }
    case 4: {
      loginType = "手机号密码登录";
      break;
    }
    case 5: {
      loginType = "手机号验证码登录";
      break;
    }
    case 6: {
      loginType = "google登录";
      break;
    }
    case 7: {
      loginType = "facebook登录";
      break;
    }
    case 8: {
      loginType = "邮件登录";
      break;
    }
    case 9: {
      loginType = "AppIeId";
      break;
    }
    case 10: {
      loginType = "阿里云";
      break;
    }
  }
  return loginType;
};
//项目切换
exports.projectType = (Type) => {
  let projectType = "";
  switch (Type) {
    case 1: {
      projectType = "未来之光";
      break;
    }
    case 2: {
      projectType = "小象脑力";
      break;
    }
    case 3: {
      projectType = "思维旅行";
      break;
    }
    case 4: {
      projectType = "倍速阅读";
      break;
    }
   
  }
  return projectType;
};

//判断男女
exports.Sex = (sex) => {
  let Sex = "";
  switch (sex) {
    case -1: {
      Sex = "未知";
      break;
    }
    case 0: {
      Sex = "男";
      break;
    }
    case 1: {
      Sex = "女";
      break;
    }
    
  }
  return Sex;
};
//支付方式
exports.PriceFrom = (Num) => {
  let PriceFrom = "";
  switch (Num) {
    case 1: {
      PriceFrom = "微信";
      break;
    }
    case 2: {
      PriceFrom = "支付宝";
      break;
    }
    case 3: {
      PriceFrom = "IOS";
      break;
    }
    case 4: {
      PriceFrom = "华为";
      break;
    }
    case 5: {
      PriceFrom = "金币";
      break;
    }
    case 6: {
      PriceFrom = "花呗分期";
      break;
    }
    case 7: {
      PriceFrom = "微信扫码";
      break;
    }
  }
  return PriceFrom;
};

//账号身份
exports.RulesType = (Rules) => {
  let RulesType = "";
  switch (Rules) {
    case "1": {
      RulesType = "访客";
      break;
    }
    case "2": {
      RulesType = "运营";
      break;
    }
    case "3": {
      RulesType = "维护";
      break;
    }
    case "4": {
      RulesType = "Leader";
      break;
    }
    case "5": {
      RulesType = "SuperLeader";
      break;
    }
  }
  return RulesType;
};
//时间过滤
exports.DateFormat = (originVal) => {
  const dt = new Date(originVal)
 
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
 
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
 
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
}
Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
      "M+": this.getMonth() + 1, // 月份
      "d+": this.getDate(), // 日
      "h+": this.getHours(), // 小时
      "m+": this.getMinutes(), // 分
      "s+": this.getSeconds(), // 秒
      "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
      "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
          return fmt;
}


