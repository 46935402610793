import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import filters from "./assets/js/filters";
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));
import "./assets/style/Public-css/base.css";
import "./assets/style/Website-css/website.css";
import "./assets/style/Mobile-css/mobile.css";
import "./assets/style/Public-css/animate.min.css";
import "./assets/style/Public-css/meda.css";
import "./assets/js/rem";
import scroll from "vue-seamless-scroll";
import animated from "animate.css";
import animate from "animate.css";
import MetaInfo from 'vue-meta-info'
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
Vue.use(MetaInfo);
Vue.config.productionTip = false;
Vue.use(scroll);
Vue.use(ElementUI);
Vue.use(animate);
Vue.use(animated);


Vue.prototype.$axios = axios;
axios.defaults.baseURL = "/api";
axios.defaults.timeout = 8000;


router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
}
}).$mount("#app");
